<template>

  <div class="app" >

    <!-- BVB 18-11-2022 experimenting with accessing the serviceworker from GWL earlier, turned off for now -->
    <!-- <iframe class="hidden-iframe" 
          allow="geolocation *; accelerometer *"
          :src="`${ url_gwl }`">
    </iframe> -->

    <app-header class="header" @quit="handleQuit"/>

    <main-activity />


    <app-footer class="footer"/>
    <div v-if="(mainComponent && mainComponent.tag === 'setup')" class="version-number">{{appversion}}</div>

    <error-banner color="yellow" ref="errorbanner" />

    <confirm-popup ref="confirmpopup" @submit="handleConfirm" @cancel="handleCancel" />
    <timed-confirm-popup ref="timedconfirmpopup" @submit="handleConfirm" @cancel="handleCancel" />
    <quit-keuze-popup ref="quitkeuzepopup" @submit="handleConfirm" />
    <message-popup ref="messagepopup" @submit="handleConfirm" />
    <!-- <modal-popup ref="modalpopup" /> -->

    <!-- <input-popup ref="inputpopup" @submit="handleConfirm" /> -->

    <!-- dimmed screen controlled by master -->
    <div v-if="(dimscreen && !isMaster)" class="dimscreen"> 
      <div class="headerdim"></div>
      <div :class="['screendim',(mainComponent.tag === 'comic')?'comicscreendim':'']"></div>
      <!-- <img v-if="isMaster" class="button-undim"  @click="handleUnDim()"  :src="`./static/images/bt-undim-screen.png`" /> -->
      <img class="button-undim"  @click="handleUnDim()"  :src="`./static/images/bt-undim-screen.png`" />
    </div>


    <password-popup ref="passwordpopup" @submit="handleConfirm" />

    <!-- Screen that shows the reload message. Might also function as a generic message screen? -->
    <div v-if="reloading" class="dimscreen"> 
      <div class="headerdim fulldim"></div>
      <div class="screendim fulldim"></div>
      <div class="messagepanel">
        Even wakker worden ...
      </div>
    </div>

    <!-- Screen that shows the reload message. Might also function as a generic message screen? -->
    <div v-if="swUpdating" class="dimscreen"> 
      <div class="headerdim fulldim"></div>
      <div class="screendim fulldim"></div>
      <div class="messagepanel">
        <!-- Er is een nieuwe versie van de Van wens naar wet app gedetecteerd. -->
        <br/>Van Wens naar Wet app wordt bijgewerkt <br/>
      </div>
    </div>

    <!-- Screen that shows the 'tik om aan te raken' screen to kick the serviceworker -->
    <div v-if="showActivatePopup" class="dimscreen" @click="handleAppActivate"> 
      <div class="headerdim fulldim"></div>
      <div class="screendim fulldim"></div>
      <div class="messagepanel">
        <!-- Er is een nieuwe versie van de Van wens naar wet app gedetecteerd. -->
        <br/>Tik op dit scherm om de app te activeren<br/>
      </div>
    </div>

    <!-- update exitst, currently not in use -->
    <!-- <div v-if="updateExists" class="dimscreen"> 
      <div class="headerdim"></div>
      <div class="screendim updatescreendim"></div>
      <div class="messagepanel">
        Er is een nieuwe versie van de Van Wens naar Wet app.
        <br/>Druk op 'update' om de nieuwe versie te activeren.
        <app-button class="updatebutton" color="purple" bevel="true" caption="update"  @click="refreshApp" />
            
      </div>
    </div> -->



  </div>


</template>

<script>

import * as cheet from 'cheet.js'


import AppHeader from  "./components/AppHeader.vue";
import AppFooter from  "./components/AppFooter.vue";
import MainActivity from './components/MainActivity.vue'
import ConfirmPopup from  "@/components/ui/ConfirmPopup.vue";
import TimedConfirmPopup from  "@/components/ui/TimedConfirmPopup.vue";

import PasswordPopup from  "@/components/ui/PasswordPopup.vue";
import MessagePopup from  "@/components/ui/MessagePopup.vue";
// import ModalPopup from  "@/components/ui/ModalPopup.vue";

import QuitKeuzePopup from  "@/components/ui/QuitKeuzePopup.vue";
import ErrorBanner from  "@/components/ui/ErrorBanner.vue";
// import AppButton from "@/components/ui/AppButton.vue"

import {mapState} from 'vuex';
import io from 'socket.io-client';


//import createLog from 'localstorage-logger';

// BVB EventBus is probably obsolete  
//import EventBus from './event-bus';

export default {
  components: {
    AppHeader,
    AppFooter,
    // AppButton,
    MainActivity,
    ConfirmPopup,
    PasswordPopup,
    QuitKeuzePopup,
    TimedConfirmPopup,
    MessagePopup,
    // ModalPopup,
    ErrorBanner
  },
  data() {
    return {
      // BVB 24/09/20 this enables the download button
      // refreshing: false,
      // registration: null,
      // updateExists: false,
      swUpdating: false,
      showActivatePopup: true
    }
  },
  computed: {
    url_gwl() {
      return process.env.VUE_APP_GRONDWETLOOP;
    },
    appversion() {
      return process.env.VUE_APP_VERSION;
    },
    ...mapState('user', {
        isMaster: state => state.isMaster,
        roomname: state => state.roomname
    }),
    ...mapState('screen', {
        dimscreen: state => state.dimscreen,
        mainComponent: state => state.mainComponent
    }),
    ...mapState('hardware', {
        sleep: state => state.sleep,
        connected: state => state.connected
    }),
    // to monitor changes in the background that need to close the password popup
    ...mapState('socket', {
        last_action: state => state.last_action
    }),
    ...mapState([ 'showActivityCompletePopup',
                  'showMasterIsActivePopup',
                  'lastLoginMaster',
                  'errorMessage', 
                  'reloading']
                ),
  }, 
  watch: {
    sleep: function(isInSleep) {
      if (!isInSleep) {
        this.$store.dispatch('reloadAppState')       
        .then(() => {
          this.$refs.messagepopup.hide();
        })
        .catch(error => {
          this.$refs.messagepopup.show(error);
        });
      }
    },
    connected: function(isConnected) {
      this.$refs.messagepopup.hide();

      if (isConnected){
        this.$store.dispatch("userdata/saveUnsavedActivitiesToServer",null,{root:true})
        this.$store.dispatch('reloadAppState')
        .then(() => {
          clearTimeout(this.popuptimer);
          //this.$refs.messagepopup.hide();
        })
        .catch(error => {
            this.$refs.messagepopup.show(error);
        });
      }
      else {
          this.popuptimer = setTimeout(()=>{
            let key = "geen-verbinding-tablet";

            if (this.isMaster) {
              key += '-begeleider';
            }
            this.$refs.messagepopup.show(key);

          },1000);
      }
    },
    roomname: function(val) {
      // we use the roomname to determine if the session was closed (because the game ended)
      // in that case, the roomname became empty. 
      // if the maincomponent is in the setup state, show the serviceworker update panel

      if (val === '' && this.mainComponent.tag === 'setup') {
        this.showActivatePopup = true;
      }

    },
    errorMessage: function(msg) {
      console.log(msg)
      if (msg != '') {
        //this.$refs.errorbanner.showErrorString(msg);
      }
      else {
        this.$refs.errorbanner.clearError();
      }
    },
    showActivityCompletePopup: function (bool) {
      if (bool) {
        this.$refs.timedconfirmpopup.show("activity-time-out",70);
      }
    },
    showMasterIsActivePopup: function (bool) {
      if (bool) {
        let str = this.$refs.confirmpopup.getMessage("master-active");
        str = this.lastLoginMaster + str;
        this.$refs.confirmpopup.showString(str, "master-active");
      }
    },
    last_action:function() {
      this.$refs.passwordpopup.hide();
      this.$refs.messagepopup.hide();
    }
  }, 
  methods: {
    

    // BVB 24/09/20 this enables the download button
    // showRefreshUI (e) {
    //   this.registration = e.detail;
    //   this.updateExists = true;
    // },
    // refreshApp () {
    //   this.updateExists = false;
    //   if (!this.registration || !this.registration.waiting) { 
    //     return; 
    //   }
    //   this.registration.waiting.postMessage('skipWaiting');
    // },
    showUpdatingMessage(e) {
      console.log(`sw updating`,e);

      this.swUpdating = true;
      // let registration = e.detail;
      // this.registration.waiting.postMessage('skipWaiting');
    },
    hideUpdatingMessage(e) {
      console.log(`sw updated`,e);

      this.swUpdating = false;
      this.showActivatePopup = false;
      // let registration = e.detail;
      // this.registration.waiting.postMessage('skipWaiting');
    },
    handleAppActivate() {
      // kick the serviceworker
      if (this.serviceworkerhook) {
        console.log("updating serviceworker manually")
        this.serviceworkerhook.update();
      }
      // hide the popup
      this.showActivatePopup = false;
    },
    saveServiceWorkerRegistration(e) {

      console.log(`sw registered`,e);

      // since this is the registration (= app startup) we do not need the 
      // activatepopup as the sw will be checked automatically
      this.showActivatePopup = false;
      // save a reference to the serviceworker
      this.serviceworkerhook = e.detail;
    },
    handleQuit() {
      let action = "quit";
              // this.$refs.timedconfirmpopup.show("activity-time-out");

      if (this.isMaster) {
        action += '-begeleider';
        this.$refs.quitkeuzepopup.show(action);
      }
      else {
        this.$refs.passwordpopup.show(action);
      }
    },
    handleUnDim() {
      //this.$refs.confirmpopup.show("undim");
      this.$refs.passwordpopup.show("undim-leerling");
    },
    handleCancel(action) {
        switch(action) {
            case "activity-time-out": {
              this.$store.dispatch("clearActivityTimesUp");       
              // user does not want to save, back to main screen
              this.$store.dispatch('screen/returnToProgram',null,{root:true});
              break;
            }
            case "master-active": {
              this.$store.dispatch("clearMasterActive");       
              this.$store.dispatch("closeSession",'self');
              break;
            }            
        }
    },
    handleConfirm(action, data) {
        switch(action) {
            case "undim": {
              this.$store.dispatch('screen/dimScreens', false);
              break;
            }
            case "undim-leerling": {
              this.$store.dispatch('screen/dimJustThisScreen', false);
              break;
            }
            case "quit": {
              this.$store.dispatch("closeSession",'self');
              break;
            }
            case "quit-begeleider": {
              //this.$store.dispatch("closeSession");
              if (data === 'all') {
                  this.$refs.confirmpopup.show("quit-begeleider-all");
              }
              else {
                  this.$refs.confirmpopup.show("quit-begeleider-self");
              }
              break;
            }
            case "quit-begeleider-all": {
              this.$store.dispatch("closeSession",'all');
              break;
            }
            case "quit-begeleider-self": {
              this.$store.dispatch("closeSession", 'self');
              break;
            }
            case "activity-time-out": {
              this.$store.dispatch("clearActivityTimesUp");       
              // user wants to save
              this.$store.dispatch('userdata/submitActivityData')
              .then(() => {
                this.$store.dispatch('screen/returnToProgram',null,{root:true});
              })
              .catch(error => {
                  console.error(error);
                  //this.$refs.errorbanner.showErrorString(error);
              });       
              break;
            }
            case "master-active": {
              this.$store.dispatch("clearMasterActive");       
              break;
            }            
        }
    },
//         case "resume": {
//           if (this.$refs.code.value == this.$store.getters['alert/specs'].code ){
//             EventBus.$emit('popupresult-' + this.alert_action);
//             this.$store.dispatch('alert/clearAlert');
//           }
//           else {
//             this.$refs.errorbanner.showError("password-incorrect");
//           }
//           // this.$store.dispatch('setResuming',true);
//           // this.$emit("submit");
//           break;

},
  mounted() {
    console.log("--mounted--")
    console.log("this is it. here we go!")
    console.log(process.env);
    
    cheet('s t a t e', () => {
      console.log("%c=== dumping state ===", 'color:red');
     // console.log(Array.from(document.querySelectorAll('*')).find(e => e.__vue__).__vue__.$store.state)
     console.log(this.$store.state)
      console.log("%c===     end state ===", 'color:red');
    })
    
    // const log = createLog({
    //     logName: 'vwnw-log',
    //     maxLogSizeInBytes: 500 * 1024 // 500KB
    // });

    // log.info('something', {
    //   foo: 'bar'
    // }, 42);

    // disable back button
    history.pushState(null, document.title, location.href);
    window.addEventListener('popstate', function (event)
    {
      history.pushState(null, document.title, location.href);
    });

    // BVB 18-06-2024 moved socket instantiation to from main.js here, also 
    // connect message event to store socket_message 
    // event, as this was handled by VueSocketIOExt (also removed)

    const socket = io(process.env.VUE_APP_SOCKET,{
        autoConnect: false,
        secure:true
    });

    socket.on("connect", () => {
      console.log('==connected==')
     // console.log(this.socket.id); 
    });

    socket.on("connect_error", (e) => {
      // socket.auth.token = "abcd";
      // socket.connect();
      console.log("connect error")
      console.log(e);
    });
    
    socket.on("disconnect", (reason) => {
      console.log(`disconnected: ${reason}`)
    });
    
    // BVB 18-06-2024  connect message event to store socket_message 
    // event, as this was handled by VueSocketIOExt (also removed)
    socket.on("message", (e) => {
      console.log('MESSAGE')
      console.log(e);
      this.$store.dispatch('socket/socket_message',e);  
    });

    this.$store.dispatch('socket/setSocket',socket);  

  },
  beforeCreate() {
    console.log("--init app--")
  //  console.log(this.$store)
    this.$store.dispatch("initApp");
    //this.$store.commit('CHECK_LOCALSTORAGE');
  },
   created() {

    console.log("--created--")
    
    //console.log("CREATED 2")

     // rewrite viewport based on resolution
     let origW = 962;
      let origH = 601;

      // calculate width and height ratio's to the original width and height
      let rw = screen.availWidth / origW;
      let rh = screen.availHeight / origH;

      // use the smallest of the two 
      let ratio = Math.min(rw,rh);

      //if (ratio != 1) {
        // rewrite the viewport attribute with the new scale
        let viewport = document.querySelector("meta[name=viewport]");
        viewport.setAttribute('content', 'initial-scale=' + ratio + ', maximum-scale=' + ratio + ', user-scalable=0');  
      //}

      console.log("Total width/height: " + screen.width + "*" + screen.height);
      console.log("Available width/height: " + screen.availWidth + "*" + screen.availHeight );
      // console.log("Color depth: " + screen.colorDepth );
      console.log(" screen.pixelDepth: " + screen.pixelDepth );
      console.log(ratio)
      console.log(screen)
      // disable contextmenu/long press
      //console.log(process.env.NODE_ENV)
      if (process.env.NODE_ENV === "production") {
        window.addEventListener("contextmenu", function(e) { e.preventDefault(); })
      }

      window.addEventListener('visibilitychange', (e) => {
        this.$store.dispatch('hardware/setSleep', document.hidden);
      });
      window.addEventListener('offline', () => {
        this.$store.dispatch('hardware/setConnected', false)
      });
      window.addEventListener('online', () => {
        this.$store.dispatch('hardware/setConnected', true)
      });
     

      navigator.connection.addEventListener('change', ()=>{
        this.$store.dispatch('hardware/connectionChange')
      })
      if(navigator.getBattery) {
        navigator.getBattery().then((battery) => {

          battery.addEventListener("chargingchange", ()=>{
            this.$store.dispatch('hardware/batteryChange',battery)
          }, false);
          battery.addEventListener("levelchange", ()=>{
            this.$store.dispatch('hardware/batteryChange',battery)
          }, false);

          this.$store.dispatch('hardware/batteryChange',battery)
        });
      }
      else {
        console.warn("Your browser does not have the navigator.mozBattery object");
      }

      this.popuptimer = null;
      this.serviceworkerhook = null;

      document.addEventListener(
        'swUpdating', this.showUpdatingMessage, { once: true }
      );
      document.addEventListener(
        'swUpdated', this.hideUpdatingMessage, { once: true }
      );
      document.addEventListener(        
        'swRegistered', this.saveServiceWorkerRegistration, { once: true }
      );      

      // listen to a quit message from the grondwetloop in the iframe
      window.addEventListener("message", (event) => {
        if (event.origin !== process.env.VUE_APP_GRONDWETLOOP)
          return;

        if (event.data === "quit") {
          this.handleQuit();
        }
        // BVB 18-11-2022 experimenting with accessing the serviceworker from GWL earlier, turned off for now
        // else if (event.data === "gwl-sw-updating") {
        //   const e = {
        //     originalevent: event,
        //     sender: 'gwl'
        //   }
        //   this.showUpdatingMessage(e);
        // }
        // else if (event.data === "gwl-sw-updated") {
        //   const e = {
        //     originalevent: event,
        //     sender: 'gwl'
        //   }
        //   this.hideUpdatingMessage(e);
        // }
        // ...
      }, false);

      // BVB 24/09/20 this enables the download button
      //   document.addEventListener(
      //     'swUpdated', this.showRefreshUI, { once: true }
      //   );
      //   navigator.serviceWorker.addEventListener(
      //     'controllerchange', () => {
      //       if (this.refreshing) return;
      //       this.refreshing = true;
      //       window.location.reload();
      //     }
      //   );
    }
};
</script>


<style>
/* html {
  overflow: hidden !important;
} */
 :root{
    --purple-dark-color: #98177C;
    --purple-middle-color: #B65CA3; 
    --purple-light-color: #E0B9D7;

    --yellow-dark-color: #FCC243;
    --yellow-middle-color: #fdda8e;

    /* --accent-color-1: #C5BBAF;  */
    --gray-color-light: #E2DDD7;
    --gray-color-middle: #dad3cb;
    --gray-color-dark: #C5BBAF;

    --dark-text-color: #000000;
    --light-text-color: #ffffff;

    --scale: 1.3;

}

/*

width = calc(1024px / var(--scale) );

*/

* {
  margin:0;
  box-sizing: border-box; 
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
}

body {
  position: relative;
  min-height: 100vh;
  overscroll-behavior-y: contain;
  /* transform-origin: top left;
  transform: scale(1.00); */
}
textarea:focus, input:focus{
    outline: none;
}

.list-item {
  margin-top: 10px;
}

.app {
  display: grid;
  width:100vw;
  height: 100vh;
  overflow: hidden;
  background-color: lightgrey;
  font-family: 'Fira Sans Regular';
}



.dimscreen {
  position: absolute;
  width:100vw;
  height:100vh;
  z-index: 1;
}

.headerdim {
  width:100vw;
  height: calc(83px / var(--scale) );
  background-color: black;
  opacity: 0.5;
}

.screendim {
  width:100vw;
  height:90vh;
  background-color: black;
  opacity: 1;
}

.comicscreendim {
  opacity: 0.5;
}

.updatescreendim {
  opacity: 0.7;
}

.fulldim {
  opacity: 0.4;
}

.messagepanel {
  position: absolute;
  width:50vw;
  height:30vh;
  margin-left: -25vw;
  margin-top: -15vh;
  top: 50%;
  left: 50%;
  background-color: var(--yellow-dark-color);
  /* border:1px solid white; */
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.updatebutton {
  margin:20px;

}
.button-undim {
    position: absolute;
    height: calc(58px / var(--scale) );
    top:calc(124px / var(--scale) );
    left:calc(73px / var(--scale) );
}

.header {
  position: absolute;
  width:100vw;
  height: calc(83px / var(--scale) );
  /* z-index:1; */
}

.footer {
  position: absolute;
  width:100vw;
  height: calc(43px / var(--scale) );

  /* height:32px; */
  bottom:0;
  z-index:19;
}

.version-number {
  position: absolute;
  font-family: 'Fira Sans Bold';
  color: var(--yellow-dark-color);
  bottom:0;
  right:0;
  padding:5px 10px;
  z-index:1;
}

/* in use by activities */
.page {
    position: relative;

    /* flex-grow: 1; */
    /* display: flex;
    flex-direction: column;
    align-items: center;
    background-color: green; */
}

.main {
    position: relative;

  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* flex-grow:.7; */
  /* margin-top: 10vh; */
  /* background-color: red; */
}

.hidden-iframe {
  position: absolute;
  visibility: hidden;
  bottom:0;
  right:0;
  width: 0;
  height: 0;;
}



</style>
